<template>
    <main class="block-share">
        <h1>{{ $lang.pages.compartir.share_question }}</h1>
        <nav class="block-share__list">
            <NuxtLink no-prefetch v-for="(item, key) in items" :key="key" class="item" :to="item.link">
                <img :src="item.logo" alt="Logo de ofertas" class="item__image" />
                <div class="item__content">
                    <h2>
                        {{ item.title }}
                        <img :src="$assets.gray.rightArrowSmall" alt="Icono de flecha derecha" />
                    </h2>
                    <p>
                        {{ item.description }}
                    </p>
                </div>
            </NuxtLink>
        </nav>
    </main>
</template>

<script lang="ts" setup>
const { $assets, $lang } = useNuxtApp()
const items = [
    {
        logo: $assets.illustration.share.offer,
        title: $lang.pages.compartir.offer,
        description: $lang.pages.compartir.share_offer,
        link: `/${$lang.routes.share}/${$lang.routes.offer}`,
    },
    {
        logo: $assets.illustration.share.coupon,
        title: $lang.pages.compartir.coupon,
        description: $lang.pages.compartir.share_coupon,
        link: `/${$lang.routes.share}/${$lang.routes.coupon}`,
    },
    {
        logo: $assets.illustration.share.chat,
        title: $lang.pages.compartir.chat,
        description: $lang.pages.compartir.open_thread,
        link: `/${$lang.routes.share}/${$lang.routes.chat}`,
    },
]

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.compartir.title_seo
const description = $lang.pages.compartir.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.block-share {
    @apply container flex flex-col items-center gap-y-4 py-7 lg:max-w-screen-xl;
    h1 {
        @apply text-center text-3xl font-semibold text-gray-800;
    }
    &__list {
        @apply mt-4 flex max-w-2xl flex-col items-stretch space-y-2;
        .item {
            @apply flex rounded-2xl border bg-white p-3 transition duration-100 ease-in-out lg:shadow-site-primary-shadow lg:hover:border-site-primary lg:hover:shadow-md;
            &__image {
                @apply w-16;
            }
            &__content {
                @apply ml-2 w-full;
                h2 {
                    @apply flex justify-between text-lg font-semibold;
                    img {
                        @apply flex h-3 items-end justify-end self-start;
                    }
                }
            }
        }
    }
}
</style>
